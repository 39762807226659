.wrapper
    padding: 16px
    border-radius: 16px 16px 16px 0
    background: #F3F6F9
    margin-bottom: 8px

    &:last-child
        margin-bottom: 0

.buttonClipper
    margin-top: 12px

.star
    display: flex
    align-items: center
    text-align: center
    padding-left: 5px
    font-weight: bold
    //height: 100%

    &Company
        display: flex
        align-items: center
        text-align: center
        padding-left: 24px
        color: #818894
        font-weight: bold
        //height: 100%

.allInfo
    display: flex
    align-items: center
    margin-bottom: 8px
    justify-content: space-between

.excClipper
    position: relative
    max-height: 60px
    overflow-y: hidden

.info
    display: flex
    align-items: center
    margin-bottom: 8px

    p
        margin: 0
        padding: 0
        font-weight: bold
        font-size: 14px
        line-height: 20px

    time
        font-size: 12px
        line-height: 16px

.avatar
    margin-left: 0

.link
    font-weight: bold


.user
    margin-left: 8px

.photoReview
    padding-top: 20px
    overflow-x: auto
    overflow-y: hidden
    display: flex

.rating
    display: flex
    align-items: center
    text-align: center

.img
    display: flex
    max-height: 80px
    max-width: 120px
    padding-right: 8px
    scroll-snap-align: start

    &All
        padding-top: 20px
        display: flex
        position: relative
        overflow-x: auto
        overflow-y: hidden
        width: 100%
.coment
    position: relative
    max-height: 80px
    overflow-y: hidden

    &:before
        content: ''
        position: absolute
        bottom: 0
        width: 100%
        height: 35px
        background: linear-gradient(to top, #fff, transparent)

@media (max-width: 550px)
    .wrapper
        width: 384px
    .allInfo
        display: grid
        grid-template-rows: 1fr 0fr

    .img
        height: 80px
        width: 100px


    .rating
        display: grid
        grid-gap: 10px

    .star
        &Company
            padding-left: 4px
    .excClipper
        margin-top: 10px
@media (max-width: 390px)
    .wrapper
        width: 100%
