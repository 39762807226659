.pagination
    display: flex
    list-style: none
    padding: 0
.pageItem
    border: 1px solid rgba(0, 0, 0, 0.15)
    border-radius: 4px
    margin-right: 8px
    cursor: pointer
    &:hover
        background-color: var(--button-color)
    &Active
        background-color: var(--primary-color)
        &:hover
            background-color: var(--primary-color)

    &Link
        width: 32px
        height: 32px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        font-style: normal
        font-weight: 600
        font-size: 14px
        line-height: 16px


.pageItemActive .pageItemLink
    color: var(--accent-color)

.arrow
    width: 32px
    height: 32px
    margin-left: 13px
    background-image: url("../../public/icons/pagination/PaginationArrow.svg")
    background-repeat: no-repeat
    background-position: center
    &Prev
        margin-right: 21px
        background-image: url("../../public/icons/pagination/PaginationArrow.svg")
        background-repeat: no-repeat
        background-position: center
        transform: rotate(180deg)
    &Link
        width: 32px
        height: 32px
        display: flex
        justify-content: center
        align-items: center
        border: none
        cursor: pointer


.disabledArrow
        opacity: 0.5
        cursor: not-allowed

.paginationDisabled
    opacity: 0.7
    //border: 1px solid rebeccapurple
    cursor: not-allowed
    background-color: rgba(0, 0, 0, 0.15)
    color: #fff

    &:hover
        background-color: rgba(0, 0, 0, 0.15)
    &Active
        background-color: rgba(0, 0, 0, 0.15)
        &:hover
           background-color: rgba(0, 0, 0, 0.15)
