.topBar
    display: flex
    margin-top: 16px
    margin-bottom: 24px
    justify-content: space-between
    align-items: center

.headShips
    display: flex
    justify-content: space-between
    max-height: 60px
    align-items: center

    &Link
        display: flex
        align-items: center
        text-align: center
        color: #0281FF
        text-decoration: none
        font-size: 16px
        line-height: 24px
        font-weight: 700
        gap: 10px


.buttonWrapper
    margin-top: 8px

.downloadPDFWrapper
    display: flex
    align-items: center

.bannerCookie
    width: 100%
    margin-bottom: 48px

.actualPanel
    display: flex
    justify-content: center
    width: 100%

.innerBanner
    margin: 32px 0 32px 0

.tourIcon
    display: flex
    align-items: center

.marginBage
    margin-right: 8px

.filterWrapper
    padding-top: 40px

.badgeRight
    background-color: #FE0000
    padding: 6px 12px 6px 12px
    border-radius: 32px
    display: flex
    justify-content: center
    align-items: center
    height: 100%

.searchLink
    display: flex
    margin-bottom: 16px

.alertWrapper

.wrapperBorder
    margin-top: 16px
    margin-bottom: 20px
    border: 1px solid rgba(0, 133, 255, 0.5)
    box-sizing: border-box
    border-radius: 4px

.wrapperBorderTour
    margin-top: 8px
    margin-bottom: 12px
    border-radius: 24px
    border: 1px solid #17A0E5
    box-sizing: border-box

.modal
    width: 100%

    &Footer
        display: flex
        justify-content: space-between
        align-items: center

    &Description
        margin-bottom: 16px
        white-space: pre-wrap


    &Duration
        display: flex
        color: #3E4752
        font-size: 14px
        font-weight: 600
        line-height: 16px

        &Inside
            color: #3E4752
            font-size: 12px
            font-weight: 400
            line-height: 16px
            padding-right: 5px

.excursionIncluded
    display: flex
    justify-content: space-between
    align-items: center
    min-width: 184px
    height: 24px
    padding: 4px 12px 4px 12px
    gap: 4px
    border-radius: 20px
    background-color: #E6F2FF

.excursionExtra
    display: flex
    justify-content: space-between
    align-items: center
    min-width: 144px
    height: 24px
    padding: 4px 12px 4px 12px
    gap: 4px
    border-radius: 20px
    background-color: #FDEFE9

.modalMapWrap
    display: flex
    flex-direction: column
    row-gap: 16px

.headTitle

.progressBadge
    display: inline-flex
    position: relative
    top: -4px

.modalMap
    position: relative
    width: 100%
    max-width: 720px
    height: 240px

.wrapperText
    align-items: center
    display: flex
    padding: 8px 6px 8px 8px

.wrapperTextTour
    align-items: center
    display: flex
    padding: 8px 16px

.faq
    padding-top: 4px
    padding-left: 4px
    list-style-type: " • "

.faqList
    padding-left: 16px

.contentWrapper
    display: grid
    grid-template-columns: 1fr 304px
    grid-gap: 96px
    margin-top: 20px

    &NoActual
        grid-template-columns: 1fr

.mainContent
    margin-top: 12px

.aside
    height: 100%

.section
    margin-top: 26px
    width: 100%

.wrapperSize
    width: 100%

.list
    margin-left: 10px
    padding-left: 0

    &Item
        padding-left: 4px
        list-style-type: " • "
        list-style-position: outside

.wrapperBorderExc
    margin-top: 16px
    margin-bottom: 20px
    box-sizing: border-box
    border-radius: 4px

.wrapperTextExc
    align-items: center
    display: flex
    padding: 8px 6px 8px 8px
    background: #F3F6F9
    border: none
    cursor: pointer

.additional

    .head
        display: flex
        align-items: center

    p
        font-size: 14px
        line-height: 20px
        font-weight: bold
        color: #616770
        margin: 0 0 0 8px

.innerAside
    // position: sticky
    // top: 140px

.offers
    grid-column: 1
    grid-rows: 1

.list
    margin-left: 10px
    padding-left: 0

    &Item
        padding-left: 4px
        list-style-type: " • "
        list-style-position: outside

.dateBlock
    margin-bottom: 8px

.reviews
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 54px

    &Info
        display: flex
        align-items: center
        // width: 182px
        // height: 24px
    &Count
        background-color: var(--darken-color)
        padding: 4px 8px
        color: #fff
        margin-left: 16px
        border-radius: 50%

.shema
    padding-top: 64px

    &Text
        margin-bottom: 16px

.cards
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 16px
    margin-top: 16px

.cardShips
    padding-right: 8px
    padding-left: 8px
    padding-top: 16px

.route
    max-width: 700px
    margin-bottom: 18px

.excClipper
    position: relative
    max-height: 110px
    overflow-y: hidden

.picklPrice
    display: flex

.Garantii
    margin-bottom: 26px

.yandexButton
    padding-top: 16px

.section
    margin-top: 26px
    width: 100%

    &Content
        margin-bottom: 16px

.ServDesc
    position: relative
    max-height: 170px
    overflow-y: hidden
    margin-bottom: 20px

    &.open
        max-height: none
        overflow-y: visible

        &:after
            height: 0
            background: none

.badge
    margin-top: 16px
    margin-bottom: 16px
    display: flex
    align-items: center
    padding: 8px
    border-radius: 4px
    border: 1px solid
    border-image-width: 1px
    border-image-slice: 1
    border-image-source: linear-gradient(92.15deg, rgba(0, 242, 242, 0.5) 6.98%, rgba(0, 133, 255, 0.5) 94.5%)

.exctext
    padding-bottom: 15px

.descriptionText
    white-space: pre-wrap

.schedule
    display: flex
    flex-direction: column
    margin-top: 12px

    &Item
        display: flex
        margin-bottom: 4px

    &Date
        display: flex
        min-width: 56px
        font-weight: bold
        font-size: 14px
        line-height: 20px
        padding-right: 5px

    &Info
        margin-bottom: 8px

        &:last-child
            margin-bottom: 0

    &InfoHeader
        display: flex
        align-items: center
        margin-left: -6px

@media (max-width: 1199px)
    .aside
        height: auto

    .mainContent
        padding-bottom: 16px

@media (max-width: 991px)
    .cards
        grid-template-columns: 1fr

    .topBar
        display: flex
        margin-bottom: 16px

    .contentWrapper
        display: grid
        grid-gap: 0
        grid-template-columns: 1fr
        margin-top: 16px

    .badge
        width: 100%

    .innerAside
        position: static

    // .allRiviews
    //     padding-right: 46px

@media (max-width: 768px)

    .downloadPDFWrapper
        margin-bottom: 16px

    .topBar
        display: flex
        flex-wrap: wrap

    // .allRiviews
    //     padding-right: 46px

    .mainContent
        margin-top: 0

    .modal
        width: 100%

        &Footer
            flex-direction: column
            align-items: flex-start
            gap: 12px

.to
    // margin-top: 15px
    padding-bottom: 15px

.transfer
    margin-bottom: 10px
    margin-top: 10px

.listFull
    width: 100%
    &Paragraph
        margin-top: 16px
.wrapperNoExcursion
    padding: 8px 16px 16px 16px
    background-color: var(--accent-color) !important
.townPhoto
    grid-template-columns: 1fr 1fr 1fr 1fr
    grid-template-rows: auto
    display: grid
    grid-gap: 4px
    z-index: 999999999
    height: auto
    margin-bottom: 6px

.portFoto
    height: 100%
    width: 100%
    padding-top: 10px

.imgTown
    //cursor: pointer
    height: 100%
    z-index: 999999999
    object-fit: fill
    object-position: center

.cancelDesc
    font-size: 17px

.wrapperSize
    width: 100%

.facilitiesButton
    padding-top: 20px

.fixedBtnWrapper
    position: fixed
    bottom: 15px
    left: 15px
    right: 15px
    display: flex
    align-items: center

.iconBorder
    width: 40px
    height: 40px
    border-radius: 32px
    display: flex
    align-items: center
    justify-content: center
    background: #F3F6F9
    margin-left: 16px
    border: 0

.mobileMotivator
    padding-top: 15px

.returnTrip
    color: #808080

.badgeContent
    display: flex
    justify-content: center
    flex-wrap: wrap
    white-space: nowrap

@media ( max-width: 700px )
    .imgTown
        height: 110px

@media ( max-width: 600px )
    .imgTown
        height: 95px

@media ( max-width: 450px )
    .imgTown
        height: 65px

@media ( max-width: 350px )
    .imgTown
        height: 50px

@media ( max-width: 280px )
    .imgTown
        height: 40px

@media ( max-width: 230px )
    .imgTown
        height: 30px

@media ( max-width: 800px  )

    .headShips
        display: block
        max-height: 100px

        &Link
            margin-bottom: 16px

    .badgeItem
        min-height: 50px
        max-height: 70px

        //&Class
        //    display: none

    .badgeContent
        justify-content: flex-start
        flex-wrap: nowrap
        overflow-x: auto
        height: 50px

@media ( max-width: 600px  )
    .badgeItem
        min-height: 50px
        max-height: 70px

        &Class
            // min-width: 100px

    .badgeContent

        justify-content: flex-start
        flex-wrap: nowrap
        overflow-x: auto
        height: 50px

@media ( max-width: 300px  )

    .badgeItem
        min-height: 50px
        max-height: 70px

        &Class
            // min-width: 100px

    .badgeContent
        justify-content: flex-start
        flex-wrap: nowrap
        overflow-x: auto
        height: 50px

.AlertWrapper
    display: flex
    align-items: center
    width: fit-content
    padding: 8px
    margin-bottom: 10px
    gap: 8px
    border: 1px solid #E5A35A
    border-radius: 8px

.AlertText
    display: flex
    flex-direction: column
    gap: 4px

.modalDescription
    max-height: 400px
    overflow-y: auto
