.cardList
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px

.innerBanner
    margin: 16px 0 16px 0

.cardPinkBorder
    // background-color: rgba(228, 241, 255)
    // padding: 8px
    // border-radius: 16px

@media (max-width: 767px)
    .cardList
        grid-template-columns: 1fr
