.skeleton
    padding-top: 16px

    &Block
        margin-right: 70px

.skeletonWrapper
    display: flex

.directions
    padding-top: 76px