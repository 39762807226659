.noReturn
    color: #808080

.bold
    font-weight: 700

.btn
    border: 0
    padding: 0
    color: var(--primary-color)
    background: transparent
    font-weight: 700
    font-size: 14px
    line-height: 20px
    cursor: pointer
    white-space: normal
