.dots
    display: flex
    align-items: center
    justify-content: center
    padding-top: 16px

    button
        cursor: pointer
        padding: 0
        border: 0
        font-size: 0
        border-radius: 50%
        transition: width .2s

// Views

.regularDots

    button
        width: 8px
        height: 8px
        background: #BFD3F2

        &.active
            background: #549AEB

.longDots

    button
        width: 16px
        height: 4px
        background: #818894
        opacity: .25
        border-radius: 4px

        &.active
            opacity: 1

.dynamicWidth

    button
        width: 4px
        height: 4px
        background: #818894
        opacity: .25

        &.active
            opacity: 1
            width: 16px
            border-radius: 4px
