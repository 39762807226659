.card
    border: 1px solid rgba(24, 70, 143, 0.1)
    box-shadow: 0 4px 16px rgb(24 70 143 / 5%)
    border-radius: 8px


.selectCabin
    width: 304px
    height: 180px
    background-color: #fff
    margin: 24px 16px 0 0

@media (max-width: 524px)
    .selectCabin
        width: 100%
        margin: 16px 0 16px 0
