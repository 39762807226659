.root
    max-width: 100%

.none
    object-fit: none

.cover
    object-fit: cover

.fill
    object-fit: fill

.contain
    object-fit: contain

.scale-down
    object-fit: scale-down


