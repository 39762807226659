
.accordion
  max-width: 100%
  margin-bottom: 8px

.accordionTitle
  display: flex
  flex-direction: row
  border-radius: 4px
  border: none
  justify-content: space-between
  align-items: center
  cursor: pointer
  background-color: #F3F6F9
  width: 100%
  padding-left: 16px
  padding-right: 16px
  text-align: start

.innerTitle
    font-size: 16px
    line-height: 24px
    font-weight: 700


.accordionTitle:hover
  background-color: #F3F6F9

.accordionContent
  background-color: #F3F6F9
  padding: 16px
  white-space: pre-wrap
  display: none

.accordionContentActive
  display: block

.close
    display: inline-block
    width: 38px
    height: 38px
    background: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff),#0281FF
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    background: linear-gradient(#0281FF,#0281FF),linear-gradient(#0281FF,#0281FF)
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    flex-shrink: 0

.open
    display: inline-block
    width: 38px
    height: 38px
    background: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff),#0281FF
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    background: linear-gradient(#0281FF,#0281FF),linear-gradient(#0281FF,#0281FF)
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    transform: rotate(45deg)
    flex-shrink: 0


