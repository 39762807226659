.wrapper
    position: relative
    max-width: 400px
    min-width: 280px
    border: 1px solid rgba(24, 70, 143, 0.1)
    box-shadow: 0 4px 16px rgba(24, 70, 143, 0.05)
    border-radius: 8px
    overflow: hidden
    display: flex
    flex-direction: column
    background-color: #fff

.link
    text-decoration: none

.allBadge
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%

.percent
    font-weight: bold

.footerPrice
    display: inline-flex
    flex-direction: column

.shipButton
    width: 100%
    text-align: center

.main
    display: block
    text-decoration: none
    padding-bottom: 8px

.header
    padding: 16px 35px 20px 8px
    font-size: 18px
    line-height: 24px
    font-weight: 700
    display: flex
    align-items: center
    justify-content: space-between
    position: relative

.badgeRight
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    left: 255px

    &Ball
        font-weight: 800
        font-size: 24px
        position: absolute
        line-height: 125%
        top: 26px

.favorite
    padding: 0
    background: transparent
    border: 0
    margin-right: 5px
    cursor: pointer
    position: absolute
    right: 13px
    top: 18px
    z-index: 1

.pic
    position: relative
    height: 225px

    img
        width: 100%
        height: 100%
        object-fit: revert

.company
    display: flex
    height: 100%
    align-items: end
    margin-bottom: 4px
    margin-left: 6px

.blackout
    position: relative

    &:after
        content: ''
        position: absolute
        display: block
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: linear-gradient(0deg, rgba(2, 129, 255, 0.2), rgba(2, 129, 255, 0.2))

.badges
    position: absolute
    top: -12px
    left: 8px
    display: flex
    min-width: 100%
    align-items: flex-start
    flex-direction: column
    gap: 4px

.bagesBlock
    display: flex

.dots
    position: absolute
    bottom: 8px
    left: 0
    right: 0
    display: flex
    justify-content: center
    //z-index: 2

.dot
    width: 6px
    height: 6px
    background: #fff
    border-radius: 3px
    border: 1px solid rgba(#313060, .7)
    margin: 0 5px
    transition: width .2s

    &.active
        width: 20px

.morePhotos
    position: absolute
    bottom: 8px
    right: 8px
    padding: 4px 8px
    font-weight: bold
    font-size: 12px
    line-height: 16px
    color: #fff
    background: rgba(3, 18, 40, 0.5)
    border-radius: 4px

.embla
    display: block
    overflow: hidden
    position: relative
    width: 100%
    height: 100%

    &Container
        display: flex
        user-select: none
        -webkit-touch-callout: none
        -webkit-tap-highlight-color: transparent
        margin-left: -10px
        flex-grow: 1
        height: 100%

    &Slide
        position: relative
        min-width: 100%
        display: flex
        justify-content: center
        cursor: pointer

.zones
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    display: flex
    cursor: pointer
    z-index: 2

.zone
    height: 100%
    flex-grow: 1

.viewed
    position: absolute
    left: 8px
    bottom: 8px
    margin-right: -8px

.body
    margin-top: 8px
    padding: 0 8px

    & > *
        margin: 0 0 8px

.time
    font-weight: bold

.bonus
    display: flex
    align-items: center

    &Margin
        margin-right: 5px

    p
        margin: 0

    span
        font-weight: 700

.discount
    display: flex
    align-items: center

.footer
    margin: auto 8px 0
    display: flex
    align-items: center
    justify-content: space-between
    padding: 8px 0
    border-top: 1px solid rgba(3, 18, 40, 0.15)

.price
    font-weight: bold
    font-size: 18px
    line-height: 24px
    display: inline-flex

.priceInfo
    font-weight: bold
    font-size: 16px
    line-height: 24px
    display: inline-flex

.oldPrice
    font-size: 14px
    line-height: 20px
    position: relative
    display: inline-flex

    &:before
        position: absolute
        width: 100%
        height: 50%
        content: ''
        border-bottom: 2px solid #FF0000
        pointer-events: none
        transform: rotate(-5deg)

.btn
    cursor: pointer
    border: 0
    display: flex
    text-decoration: none
    font-weight: bold
    border-radius: 4px
    background-color: var(--primary-color)
    color: #fff
    padding: 16px 20px
    max-height: 56px
    justify-content: center

.btnBf
    cursor: pointer
    border: 0
    display: flex
    text-decoration: none
    font-weight: bold
    border-radius: 4px
    background-color: #FFB500 !important
    color: #000000 !important
    padding: 16px 20px
    max-height: 56px
    justify-content: center

.modalDiscounts
    display: flex
    align-items: center
    margin-bottom: 8px

@media (max-width: 920px)
    .wrapper
        max-width: 100%
        height: 100%

@media (max-width: 767px)
    .wrapper
        max-width: 100%
        height: 100%

.modalWrapper
    flex-shrink: 0
    position: relative
    width: 100%
    max-width: 720px
    min-width: 280px

.modalMap
    position: relative
    width: 100%
    max-width: 720px
    height: 240px

.modalTravelTime
    margin-bottom: 32px

.modalDiscount
    display: flex
    align-items: center
    margin-bottom: 8px

.modalBonus
    display: flex
    align-items: center
    margin-bottom: 8px

    p
        margin: 0

    span
        font-weight: 700

.modalFooter
    margin-top: 24px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 8px 0
    border-top: 1px solid rgba(3, 18, 40, 0.15)
