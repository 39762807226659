.wrapper
    display: flex
    align-items: baseline

.dates
    display: flex
    gap: 6px

.days
    font-size: 14px
    font-weight: 600
    line-height: 24px
    color: #616770
    margin-left: 8px

.time
    display: flex
    align-items: end
    font-size: 12px
    line-height: 16px
    color: #616770
    font-weight: 400

// Sizes
.default
    font-size: 14px
    line-height: 20px

    .dates
        font-weight: 700

.lg
    //align-items: flex-end
    font-weight: 700
    //line-height: 24px

    .dates
        font-size: 18px
        line-height: 24px

@media (max-width: 450px)
    .wrapper

        flex-direction: column
        align-items: start

    .days
        margin-left: 0
        margin-top: 8px
