.popularCruises
    margin-top: 80px
    &Header
        display: flex
        justify-content: space-between
.listCard
    display: grid
    grid-template-columns: repeat(3,1fr)
    grid-gap: 16px
    margin-top: 16px

.skeleton
    display: grid
    grid-template-columns: repeat(3,1fr)
    grid-gap: 16px
    margin-top: 80px

.cardScroll
    &Container
        display: flex
        overflow: auto
        scroll-snap-type: x mandatory
        height: 100%
        max-width: 100%
        margin-top: 32px
        -ms-overflow-style: none
        &::-webkit-scrollbar
            display: none
    &Item
        scroll-snap-align: center
        position: relative
        min-width: 85%
        margin-right: 16px

.mobilePagination
    display: flex
    justify-content: center
    margin-top: 16px

@media (max-width: 990px)
    .listCard
        grid-template-columns: repeat(2,1fr)

@media (max-width: 920px)
    .cardScroll
        &Item
            min-width: 58%

@media (max-width: 767px)
    .listCard
        grid-template-columns: 1fr
    
    .cardScroll
        &Item
            min-width: 85%

@media (max-width: 370px)
    .cardScroll
        &Item
            margin-right: 36px
