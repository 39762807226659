.roundImageDirections
    border-radius: 10px
    border: none
    width: 80px
    height: 80px

.roundSeaImageDirections
    border-radius: 10px
    border: none
    width: 80px
    height: 80px
    max-width: fit-content
.directionsElement
    display: flex
    padding-top: 16px
    text-decoration: none

.directionsElementText
    padding-top: 18px
    padding-left: 16px

.directions
    padding-top: 76px

    &.skinny
        padding: 0

.directionsElements
    display: grid
    grid-template-columns: 1fr 1fr

@media (max-width: 770px)
    .directionsElements
        grid-template-columns: 1fr
