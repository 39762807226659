.avatar
    background: var(--lightbackground-color)
    border-radius: 50%
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    margin-left: 4px
    margin-top: 8px

    &Img
        border-radius: 50%
        display: flex
        width: 40px
        height: 40px
