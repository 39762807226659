.btn
    padding: 4px 8px

.emblaButton
    outline: 0
    cursor: pointer
    background-color: transparent
    touch-action: manipulation
    position: absolute
    z-index: 13
    top: 50%
    transform: translateY(-50%)
    border: 0
    justify-content: center
    align-items: center
    padding: 0
    z-index: 1


.emblaButton:disabled
    cursor: default
    opacity: 0.5

.emblaButtonPrev
    left: 11px
    transform: translateY(-50%) rotate(180deg)
    z-index: 1

.emblaButtonNext
    right: 11px

.lg
    height: 28px